import { format } from "date-fns";
import { every, has, isEmpty, isEqual, sortBy } from "lodash";

import { PathologistUserResults, PortalUserResults } from "../store/userListSlice";
import {
  AllowedSlides,
  RequestFormType,
  SlideOptions,
  SlidesMap,
  UiCaseData,
} from "./CaseSchema";
import { DbCaseData } from "./CaseSchema";
import { PathologistUser } from "./PathologistSchema";
import { PortalUser } from "./PortalUserSchema";

// API responses
export interface GetCaseResponse {
  caseData: DbCaseData;
  caseInfo: CaseInfo;
}

export interface SaveCaseResponse {
  limsCaseId: string;
  caseVersionId: string;
}

export interface GetUsersResponse {
  page: number;
  pageSize: number;
  totalPages: number;
  totalUsers: number;
  users: PortalUser[] | PathologistUser[];
}

// Type guard: only portal users are assigned to (user) groups
export const isPortalUserResults = (
  response: GetUsersResponse
): response is PortalUserResults => {
  return isEmpty(response.users) || every(response.users, (user) => has(user, "groups"));
};

// Type guard: only pathologists have "known as" alternative names
export const isPathologistUserResults = (
  response: GetUsersResponse
): response is PathologistUserResults => {
  return isEmpty(response.users) || every(response.users, (user) => has(user, "knownAs"));
};

export interface GetCasesResponse {
  page: number;
  pageSize: number;
  totalPages: number;
  totalCases: number;
  cases: CaseListResult[];
}

export interface CaseListResult {
  caseData: CaseListResultData;
  caseState: CaseState;
  reasonForChange: CaseStateReasonForChange | null;
  permissions: CasePermissions;
}

export type CaseListResultData = Pick<
  DbCaseData,
  | "userGroupId"
  | "labNumber"
  | "dateReceived"
  | "recordNumber"
  | "patientIdentifier"
  | "patientSex"
  | "patientFirstName"
  | "patientSurname"
  | "caseOrigin"
  | "clinician"
  | "consultant"
  | "limsCaseId"
>;

export interface CaseStateTransitionResponse {
  internalCaseId: string;
  newVersionId: string;
  newState: CaseState;
  allowedStates: CaseState[] | null;
  permissions: CasePermissions;
}

// GET /case does return some extra properties which are not currently used
// by the UI: labKitApiVersion, pathKitApiVersion, micro, publishedReports,
// internalCaseId and versionIssuedAsReport.
export interface CaseInfo {
  caseState: CaseState;
  caseCreated: string;
  caseVersionId: string;
  limsCaseId: number | null;
  permissions: CasePermissions;
  publishedReports: PublishedReport[];
}

export enum CaseState {
  DELEGATED_TO_LIMS = "DelegatedToLims",
  AWAITING_SLIDES = "AwaitingSlides",
  READY_FOR_PATHOLOGIST = "ReadyForPathologist",
  REPORT_SUBMITTED = "ReportSubmitted",
  LOCKED = "Locked",
  WITH_THE_LAB = "WithTheLab",
}

export interface CasePermissions {
  canEditCase: boolean;
  canEditMicro: boolean;
  operations: CaseOperation[];
}

export type CaseTransition = "Lock" | "Unlock";

export interface CaseOperation {
  operation: CaseTransition;
  reasons: CaseStateReasonForChange[];
}

export enum CaseStateReasonForChange {
  LABKIT_MATERIAL_CORRECTION = "LabKitMaterialCorrection",
  LABKIT_NON_MATERIAL_CORRECTION = "LabKitNonMaterialCorrection",
  MICRO_CORRECTION = "MicroCorrection",
}

export interface PublishedReport {
  versionId: string;
  publicationTimestamp: string;
  reasonForAmendment: string | null;
}

// Codecs
export const serializeCase = (form: UiCaseData): DbCaseData => {
  return {
    ...form,
    // Either yyyy or yyyy-MM-dd for date of birth
    patientDateOfBirth: format(
      form.patientDateOfBirth,
      form.patientDateOfBirthIsYearOnly ? "yyyy" : "yyyy-MM-dd"
    ),
    // Always yyyy-MM for last diagnostic endoscopy (if given)
    dateLastEndoscopy: form.dateLastEndoscopy
      ? format(form.dateLastEndoscopy, "yyyy-MM")
      : null,
    // Always yyyy-MM-dd for all other dates
    procedureDate: format(form.procedureDate, "yyyy-MM-dd"),
    dateReceived: format(form.dateReceived, "yyyy-MM-dd"),
    slides: convertSlidesToArray(
      form.requestFormType as RequestFormType,
      form.slides as SlideOptions
    ),
  };
};

export const deserializeCase = (caseData: DbCaseData): UiCaseData => {
  // Parse strings into Date objects. The following are all valid arguments
  // to the Date() constructor: `YYYY`, `YYYY-MM`, `YYYY-MM-DD`.
  return {
    ...caseData,
    userGroupId: caseData.userGroupId ?? "", // Coerce null to empty string to satisfy UiCaseData
    pathologistId: caseData.pathologistId ?? "", // Coerce null to empty string to satisfy UiCaseData
    patientDateOfBirth: new Date(caseData.patientDateOfBirth),
    dateLastEndoscopy: caseData.dateLastEndoscopy
      ? new Date(caseData.dateLastEndoscopy)
      : null,
    procedureDate: new Date(caseData.procedureDate),
    dateReceived: new Date(caseData.dateReceived),
    slides: convertSlidesToString(caseData.slides),
  };
};

// Converts a UI string "H+E and TFF3" to its DB array, e.g. ["HAndE", "TFF3"]
const convertSlidesToArray = (
  formType: RequestFormType,
  slides: SlideOptions
): AllowedSlides[] => {
  // Always include p53 for Barrett's surveillance (TRF-2) cases, otherwise take
  // the answer from the form
  if (formType === RequestFormType.SURVEILLANCE) {
    return SlidesMap[SlideOptions.HE_TFF3_P53];
  } else {
    return SlidesMap[slides as SlideOptions];
  }
};

// Converts a DB array ["HAndE", "TFF3"] to its UI string, e.g. "H+E and TFF3"
const convertSlidesToString = (slides: AllowedSlides[]): string | null => {
  for (const [key, array] of Object.entries(SlidesMap)) {
    // Use sortBy to ensure that order of strings returned by backend array consistent with UI
    if (isEqual(sortBy(slides), sortBy(array))) return key;
  }
  // Leave the radio button unchecked if the array of slides from the DB doesn't
  // match an available radio option in the UI.
  return null;
};
