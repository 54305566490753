import { faClock, faEdit, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { CaseListResultData, CaseState } from "../../schemas/ApiSchema";
import { selectLimsOptionLabel, selectUserGroupLabel } from "../../store/metadataSlice";
import LimsCaseLink from "../common/LimsCaseLink";
import { CaseStateLabels } from "./CaseStateBanner";

export const TEST_ID_CASE_CARD_LIMS_LINK = "CaseCardLimsLink";
export const TEST_ID_CASE_CARD_EDIT_LINK = "CaseCardEditLink";

export interface CaseCardProps {
  item: CaseListResultData;
  caseState: CaseState;
  canEditCase: boolean;
}

const CaseCard = ({ item, caseState, canEditCase }: CaseCardProps): JSX.Element => {
  const {
    labNumber,
    recordNumber,
    userGroupId,
    limsCaseId,
    patientFirstName,
    patientSurname,
    patientIdentifier,
  } = item;
  const patientFullName = patientFirstName + " " + patientSurname;
  const dateReceived = "Received " + format(item.dateReceived, "d MMMM yyyy");

  // Redux
  const userGroup = useSelector(selectUserGroupLabel(userGroupId));
  const clinician = useSelector(selectLimsOptionLabel("clinicians", item.clinician));
  const caseOrigin = useSelector(selectLimsOptionLabel("caseOrigins", item.caseOrigin));
  const consultant = useSelector(selectLimsOptionLabel("consultants", item.consultant));

  const CaseStatusTag = (): JSX.Element => {
    return (
      <span
        className={`tag is-rounded ${canEditCase ? "is-link is-light" : ""}`}
        data-testid={`${labNumber}CaseCardStatusTag`}
      >
        <FontAwesomeIcon className="mr-2" icon={canEditCase ? faClock : faLock} />
        {CaseStateLabels[caseState]}
      </span>
    );
  };

  return (
    <li
      className={`cy-case-card ${!canEditCase ? "cy-case-card--locked" : ""}`}
      data-testid={`${labNumber}CaseCard`}
    >
      <Link to={labNumber} className="cy-case-card__content">
        <div className="columns is-mobile">
          <div className="column">
            <h4 className="title is-5">{item.labNumber}</h4>
          </div>
          <div className="tags" data-testid={`${labNumber}CaseCardTags`}>
            <span className="tag is-rounded">{dateReceived}</span>
            <CaseStatusTag />
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-3">
            <p className="cy-case-card__label">Kit ID</p>
            <p className="cy-case-card__value">{recordNumber}</p>
          </div>
          <div className="column is-3">
            <p className="cy-case-card__label">Patient name</p>
            <p className="cy-case-card__value">{patientFullName}</p>
          </div>
          <div className="column is-6">
            <p className="cy-case-card__label">User group</p>
            <p className="cy-case-card__value">{userGroup}</p>
          </div>
          <div className="column is-3">
            <p className="cy-case-card__label">NHS/CHI number</p>
            <p className="cy-case-card__value">{patientIdentifier}</p>
          </div>
          <div className="column is-3">
            <p className="cy-case-card__label">Consultant</p>
            <p className="cy-case-card__value">{consultant}</p>
          </div>
          <div className="column is-6">
            <p className="cy-case-card__label">Clinician / Case origin</p>
            <p className="cy-case-card__value">{clinician}</p>
            <p className="cy-case-card__value">{caseOrigin}</p>
          </div>
        </div>
      </Link>
      <div className="buttons are-small px-2 py-3">
        <Link
          to={labNumber}
          className="button is-ghost"
          data-testid={`${labNumber}CaseCardEditLink`}
        >
          <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit
        </Link>
        <LimsCaseLink
          limsCaseId={limsCaseId}
          className="button is-ghost"
          dataTestId={`${labNumber}CaseCardLimsLink`}
        />
      </div>
    </li>
  );
};

export default CaseCard;
