import classNames from "classnames";
import React, { ChangeEvent } from "react";
import { Control, Controller, FieldError, FieldValues, Path } from "react-hook-form";
import Select from "react-select";

export type FormSelectOption = { label: string; value: string };

export const FormSelectPlaceholder = "Select...";

interface FormSelectProps<T extends FieldValues> {
  id: string;
  label: string;
  control: Control<T>;
  options: FormSelectOption[];
  noMargin?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  placeholder?: string;
  help?: string;
  error?: FieldError;
}

const FormSelect = <T extends FieldValues>({
  id,
  label,
  control,
  options,
  noMargin,
  disabled = false,
  clearable = false,
  placeholder = FormSelectPlaceholder,
  help,
  error,
}: FormSelectProps<T>): JSX.Element => {
  const inputElementId = id + "Select";
  return (
    <div className={classNames({ field: true, "mb-5": !noMargin, disabled: disabled })}>
      {label && (
        <label htmlFor={inputElementId} className="label">
          {label}
        </label>
      )}
      <div className="control">
        <Controller
          name={id as Path<T>}
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              ref={ref}
              inputId={inputElementId}
              isDisabled={disabled}
              isClearable={clearable}
              options={options}
              menuPlacement="auto"
              placeholder={placeholder}
              value={options.find((option) => option.value === value) || null}
              onChange={(option) =>
                onChange((option?.value ?? "") as unknown as ChangeEvent)
              }
              className={classNames({
                "react-select-container": true,
                "has-error": error,
              })}
              classNamePrefix="react-select"
            />
          )}
        />
      </div>
      {help && !error && <p className="help">{help}</p>}
      {error && <p className="help has-text-danger">{error.message}</p>}
    </div>
  );
};

export default FormSelect;
