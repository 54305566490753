import {
  faAdd,
  faBarcode,
  faBolt,
  faQrcode,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { format } from "date-fns";
import { isNumber, mergeWith, some } from "lodash";
import React, { ForwardedRef, MouseEvent, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import WarningMessage from "../components/atoms/WarningMessage";
import Box from "../components/forms/Box";
import FormCheckbox from "../components/forms/FormCheckbox";
import FormDateInput from "../components/forms/FormDateInput";
import FormInput from "../components/forms/FormInput";
import FormSelect from "../components/forms/FormSelect";
import FormTextArea from "../components/forms/FormTextArea";
import RadioGroup from "../components/forms/RadioGroup";
import { CHECK_LIMS } from "../helpers/strings";
import { isDateMoreThanOneMonthAgo, patterns } from "../helpers/validation";
import {
  Answer,
  Best4StudyArms,
  CaseSchema,
  MacroTextButtons,
  PRAGUE_CLASSIFICATION_DIVISOR,
  RequestFormType,
  UiCaseData,
  inferDeviceBrandFromSpecimen,
  CaseSchemaOptions as staticOptions,
} from "../schemas/CaseSchema";
import {
  LimsOptions,
  selectActivePathologistOptions,
  selectLimsOptionLabel,
  selectPathologists,
  selectUserGroupLabel,
  selectUserGroups,
  selectValidUserGroupOptions,
} from "../store/metadataSlice";

export const TEST_ID_FORM_PRAGUE_CLASSIFICATION_WARNING =
  "FormPragueClassificationWarning";
export const TEST_ID_FORM_IDEAL_PROCEDURE_BUTTON = "FormIdealProcedureButton";
export const TEST_ID_FORM_PROCEDURE_DATE_WARNING = "FormProcedureDateWarning";
export const TEST_ID_FORM_DATE_RECEIVED_WARNING = "FormDateReceivedWarning";
export const TEST_ID_FORM_SAVE_CASE_BUTTON = "FormSaveCaseButton";

export const requestFormTypeLabels: Record<RequestFormType, string> = {
  [RequestFormType.REFLUX]: "Reflux",
  [RequestFormType.SURVEILLANCE]: "Barrett’s surveillance",
};

export type CaseFormMode = "create" | "update";

export type CaseFormRef = {
  submitForm: () => void;
};

interface CaseFormProps {
  formMode: CaseFormMode;
  formValues?: UiCaseData; // Only for existing cases
  formLocked?: boolean;
  limsOptions: LimsOptions;
  setFormDirty?: (x: boolean) => void;
  onSubmit: (form: UiCaseData) => void;
}

const CaseForm = React.forwardRef<CaseFormRef, CaseFormProps>(
  (
    {
      formMode,
      formValues,
      formLocked,
      limsOptions: { specimens, clinicians, caseOrigins, consultants },
      setFormDirty,
      onSubmit,
    }: CaseFormProps,
    ref: ForwardedRef<CaseFormRef>
  ): JSX.Element => {
    // Redux
    const userGroups = useSelector(selectUserGroups);
    const userGroupOptions = useSelector(selectValidUserGroupOptions);
    const pathologists = useSelector(selectPathologists);
    const pathologistOptions = useSelector(selectActivePathologistOptions);

    // Allow parent component to trigger form submission
    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    // For new cases, activate the 'Reflux' tab by default. Ensure that
    // unchecked checkboxes are initialised for new cases, and when any
    // newer fields are null for historic cases.
    const defaultFormValues: Partial<UiCaseData> = Object.freeze({
      requestFormType: RequestFormType.REFLUX,
      patientIdentifierNotProvided: false,
      patientDateOfBirthIsYearOnly: false,
      useRecordNumberForPatientName: false,
      useClinicianForProcedureSite: false,
      hasAdditionalClinicalInformation: false,
      patientIdentifierAlternative: "", // Added in 1.37
      deviceId: "", // Added in 1.42
    });

    const defaultValues: UiCaseData = mergeWith(
      formValues,
      defaultFormValues,
      (existingValue) => existingValue ?? undefined
    );

    const {
      register,
      watch,
      handleSubmit,
      control,
      formState: { errors, isDirty },
      getValues,
      setValue,
      trigger,
      reset,
    } = useForm<UiCaseData>({
      mode: "onTouched",
      defaultValues,
      resolver: yupResolver(CaseSchema),
    });

    // Watchers for interrelated fields
    const {
      recordNumber: watchRecordNumber,
      useRecordNumberForPatientName: watchUseRecordNumberForPatientName,
      specimen: watchSpecimen,
      userGroupId: watchUserGroup,
      clinician: watchClinician,
      useClinicianForProcedureSite: watchUseClinicianForProcedureSite,
      patientIdentifierNotProvided: watchPatientIdentifierNotProvided,
      patientDateOfBirthIsYearOnly: watchPatientDateOfBirthIsYearOnly,
      requestFormType: watchRequestFormType,
      hadDysplasia: watchHadDysplasia,
      patientResearchStudy: watchPatientResearchStudy,
      circumferentialLength: watchCircumferential,
      maximalLength: watchMaximal,
      isBloodOnSponge: watchIsBloodOnSponge,
      procedureDate: watchProcedureDate,
      dateReceived: watchDateReceived,
      pathologistId: watchPathologist,
      consultant: watchConsultant,
    } = watch();

    const specimenName = useSelector(selectLimsOptionLabel("specimens", watchSpecimen));
    const userGroupName = useSelector(selectUserGroupLabel(watchUserGroup));
    const consultantName = useSelector(
      selectLimsOptionLabel("consultants", watchConsultant)
    );

    // Reset the form when case data from parent component changes
    useEffect(() => reset(defaultValues), [formValues, reset]);

    // Update parent component when there are unsaved changes
    useEffect(() => {
      if (setFormDirty) setFormDirty(isDirty);
    }, [isDirty]);

    // Fill LIMS clinician and LIMS case origin fields when a user group is selected
    useEffect(() => {
      const match = userGroups.find(({ userGroupId }) => userGroupId === watchUserGroup);
      if (match?.procurementRoutes[0]) {
        const { limsClinicianId, limsCaseOriginId } = match.procurementRoutes[0];
        if (limsClinicianId && limsCaseOriginId) {
          setField("clinician", limsClinicianId);
          setField("caseOrigin", limsCaseOriginId);
        }
      }
    }, [watchUserGroup]);

    // Fill LIMS consultant field when a reporting pathologist is selected
    useEffect(() => {
      const match = pathologists.find(
        ({ pathkitUserId }) => pathkitUserId === watchPathologist
      );
      if (match?.limsConsultantId) {
        setField("consultant", match.limsConsultantId);
      }
    }, [watchPathologist]);

    // Update practitioner (procedure) location field when clinician selection changes
    useEffect(() => {
      fillProcedureSiteFromClinician(watchUseClinicianForProcedureSite);
    }, [watchClinician]);

    // Set deviceBrand hidden input value when specimen selection changes
    useEffect(() => {
      const deviceBrand = inferDeviceBrandFromSpecimen(specimenName);
      setField("deviceBrand", deviceBrand);
    }, [specimenName]);

    // Set BEST4 hidden input values when user group or consultant selection changes
    useEffect(() => {
      setField("caseOriginName", userGroupName);
      setField("consultantName", consultantName);
    }, [watchUserGroup, watchConsultant]);

    // Force 'Research study name' field update whenever BEST4 option changes
    useEffect(() => {
      trigger("patientResearchId");
    }, [watchPatientResearchStudy]);

    // Current year as two digits for placeholder attributes
    const currentYear = format(new Date(), "yy");

    // Show Prague classification warning whenever C is greater than M (unless
    // the case is locked)
    const pragueClassificationWarning: string | false =
      !formLocked &&
      isNumber(watchCircumferential) &&
      isNumber(watchMaximal) &&
      watchCircumferential > watchMaximal &&
      "C should not be greater than M. Please check with the healthcare professional who submitted the form.";

    // Show date warnings when procedure/receipt dates may be wrong at booking in
    const procedureDateWarning: string | false =
      formMode === "create" &&
      isDateMoreThanOneMonthAgo(watchProcedureDate) &&
      "This was over a month ago";
    const dateReceivedWarning: string | false =
      formMode === "create" &&
      isDateMoreThanOneMonthAgo(watchDateReceived) &&
      "This was over a month ago";

    // Always revalidate and recalculate form state when setting field values programmatically
    const setField = (field: keyof UiCaseData, value?: string | boolean | null): void => {
      setValue(field, value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    };

    const fillPatientNameFromPotId = (): void => {
      // Parse a record number into first name (e.g. 22P) and surname (e.g. 12345)
      const recordNumber: string = getValues("recordNumber");
      if (recordNumber.match(patterns.recordNumber)) {
        const [firstName, surname] = recordNumber.split(/(?<=P)/);
        setField("patientFirstName", firstName);
        setField("patientSurname", surname);
        fillPatientInitials();
      }
    };

    const fillProcedureSiteFromClinician = (checked?: boolean): void => {
      if (checked) {
        const clinician = clinicians.find((c) => c.value === watchClinician);
        // Never write [Check LIMS] into the practitionerLocation field
        if (clinician && clinician.label !== CHECK_LIMS) {
          setField("practitionerLocation", clinician.label);
        }
      }
    };

    const updatePatientIdentifierFields = (checked: boolean): void => {
      if (checked) {
        // Set 'NHS/CHI number' field to 'Not provided' and focus adjacent field
        setField("patientIdentifier", Answer.UNANSWERED);
        trigger("patientIdentifierAlternative", { shouldFocus: true });
      } else {
        // Reset and focus 'NHS/CHI number' field; clear 'Required' on adjacent field
        setField("patientIdentifier", "");
        trigger("patientIdentifierAlternative");
        trigger("patientIdentifier", { shouldFocus: true });
      }
    };

    const fillPatientInitials = (): void => {
      const firstName: string = getValues("patientFirstName").trim();
      const surname: string = getValues("patientSurname").trim();
      setField("patientInitials", firstName.substring(0, 1) + surname.substring(0, 1));
    };

    const handleClinicalTabClick = (e: MouseEvent, formType: RequestFormType): void => {
      e.preventDefault();
      if (!formLocked && formType !== watchRequestFormType) {
        // Set the active 'Clinical information' tab
        setField("requestFormType", formType);

        // Reset tab-specific fields, but don't revalidate required fields
        setValue("hasRefluxSymptoms", null, { shouldDirty: true });
        setValue("hasEoEDiagnosis", null, { shouldDirty: true });
        setValue("slides", null, { shouldDirty: true });
        setValue("hadDysplasia", null, { shouldDirty: true });
        setValue("hadEndoscopicTreatment", null, { shouldDirty: true });
        setField("dysplasiaGrade", null);
        setField("dateLastEndoscopy", null);
        setField("circumferentialLength", null);
        setField("maximalLength", null);

        // Reset 'Research study name' field it was a BEST4 study arm, because
        // each of those values is associated with a single case type. Non-BEST4
        // research study names should be left intact.
        if (some(Best4StudyArms, ["value", watchPatientResearchStudy])) {
          setField("patientResearchStudy", "");
        }
      }
    };

    // Shortcut button for procedures with no issues to save clicks
    const fillIdealProcedureAnswers = (): void => {
      setField("didPatientSwallow", Answer.YES);
      setField("didSpongeExpand", Answer.YES);
      setField("wasTensionOnThread", Answer.YES);
      setField("isDebrisOnSponge", Answer.NO);
      setField("isBloodOnSponge", Answer.NO);
      setField("takesAnticoagulants", null);
    };

    return (
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <Box title="Device details" className="tile is-child">
              <div className="columns is-multiline">
                <div className="column is-full is-half-fullhd">
                  <FormInput
                    autoFocus={formMode === "create"}
                    id="labNumber"
                    label="Lab (CYT) number"
                    disabled={formLocked}
                    maxLength={10}
                    className="is-medium is-uppercase"
                    icon={faBarcode}
                    placeholder={`${currentYear}CYT00001`}
                    error={errors.labNumber}
                    {...register("labNumber", {
                      setValueAs: (value: string) => value.toUpperCase(),
                    })}
                  />
                </div>
                <div className="column is-full is-half-fullhd">
                  <FormInput
                    id="recordNumber"
                    label="Cell preservation kit ID"
                    disabled={formLocked}
                    maxLength={8}
                    className="is-medium is-uppercase"
                    icon={faQrcode}
                    placeholder={`${currentYear}P00001`}
                    error={errors.recordNumber}
                    {...register("recordNumber", {
                      onChange: () => {
                        if (watchUseRecordNumberForPatientName) {
                          fillPatientNameFromPotId();
                        }
                      },
                      setValueAs: (value: string) => value.toUpperCase(),
                    })}
                  />
                  <FormCheckbox
                    id="useRecordNumberForPatientName"
                    label="Use as patient name"
                    disabled={
                      formLocked || !patterns.recordNumber.test(watchRecordNumber)
                    }
                    {...register("useRecordNumberForPatientName", {
                      onChange: (e) => {
                        if (e.target.checked) fillPatientNameFromPotId();
                      },
                    })}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <FormInput
                    id="deviceId"
                    label="Cell collection device ID"
                    disabled={formLocked}
                    icon={faQrcode}
                    placeholder={`(10)${currentYear}000001`}
                    help="Optional (EndoSign only)"
                    error={errors.deviceId}
                    {...register("deviceId")}
                  />
                </div>
              </div>
              <FormSelect
                id="specimen"
                label="Specimen"
                disabled={formLocked}
                control={control}
                options={specimens}
                error={errors.specimen}
              />
              <input type="hidden" id="deviceBrand" {...register("deviceBrand")} />
            </Box>
          </div>
          <div className="tile is-parent">
            <Box title="Report recipient" className="tile is-child">
              <FormSelect
                noMargin
                id="userGroupId"
                label="User group"
                disabled={formLocked}
                control={control}
                options={userGroupOptions}
                error={errors.userGroupId}
              />
              {/* TODO PLAT-4552: Figure out what to do with this checkbox */}
              <FormCheckbox
                addMargin
                id="useClinicianForProcedureSite"
                label="Use clinician as procedure site"
                disabled={formLocked || !watchClinician}
                {...register("useClinicianForProcedureSite", {
                  onChange: (e) => fillProcedureSiteFromClinician(e.target.checked),
                })}
              />
              <FormSelect
                id="clinician"
                label="LIMS clinician"
                disabled
                control={control}
                options={clinicians}
                placeholder=""
                help="⚠ Controlled by user group"
              />
              <FormSelect
                id="caseOrigin"
                label="LIMS case origin"
                disabled
                control={control}
                options={caseOrigins}
                placeholder=""
                help="⚠ Controlled by user group"
              />
            </Box>
          </div>
        </div>

        <Box title="Patient details">
          <div className="columns">
            <div className="column is-4 is-3-widescreen">
              <FormInput
                id="patientIdentifier"
                label="NHS / CHI number"
                disabled={formLocked}
                readOnly={watchPatientIdentifierNotProvided}
                error={errors.patientIdentifier}
                {...register("patientIdentifier")}
              />
              <FormCheckbox
                id="patientIdentifierNotProvided"
                label="None"
                disabled={formLocked}
                {...register("patientIdentifierNotProvided", {
                  onChange: (e) => {
                    updatePatientIdentifierFields(e.target.checked);
                  },
                })}
              />
            </div>
            <div className="column is-4 is-3-widescreen">
              <FormInput
                id="patientIdentifierAlternative"
                label="Other medical record no."
                disabled={formLocked}
                help="e.g. Hospital number"
                error={errors.patientIdentifierAlternative}
                {...register("patientIdentifierAlternative")}
              />
            </div>
            <div className="column is-4 is-3-widescreen">
              <FormDateInput
                id="patientDateOfBirth"
                label={`${watchPatientDateOfBirthIsYearOnly ? "Year" : "Date"} of birth`}
                disabled={formLocked}
                showMonthDropdown
                showYearDropdown
                mode={watchPatientDateOfBirthIsYearOnly ? "year" : "date"}
                control={control}
                error={errors.patientDateOfBirth}
              />
              <FormCheckbox
                id="useYearForDateOfBirth"
                label="Year only"
                disabled={formLocked}
                {...register("patientDateOfBirthIsYearOnly")}
              />
            </div>
          </div>
          <RadioGroup
            id="patientSex"
            label="Sex"
            disabled={formLocked}
            options={staticOptions.patientSex}
            error={errors.patientSex}
            {...register("patientSex")}
          />
          <div className="columns">
            <div className="column is-5 is-4-desktop is-3-widescreen">
              <FormInput
                id="patientFirstName"
                label="Name"
                disabled={formLocked}
                readOnly={watchUseRecordNumberForPatientName}
                error={errors.patientFirstName}
                {...register("patientFirstName", {
                  onChange: fillPatientInitials,
                })}
              />
            </div>
            <div className="column is-5 is-4-desktop is-3-widescreen">
              <FormInput
                id="patientSurname"
                label="Surname"
                disabled={formLocked}
                readOnly={watchUseRecordNumberForPatientName}
                error={errors.patientSurname}
                {...register("patientSurname", {
                  onChange: fillPatientInitials,
                })}
              />
            </div>
            <div className="column is-2">
              <FormInput
                id="patientInitials"
                label="Initials"
                disabled={formLocked}
                className="is-uppercase"
                readOnly={watchUseRecordNumberForPatientName}
                error={errors.patientInitials}
                {...register("patientInitials", {
                  setValueAs: (value: string) => value.toUpperCase(),
                })}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column is-5 is-4-desktop is-3-widescreen">
              <FormInput
                id="patientResearchStudy"
                label="Research study name"
                disabled={formLocked}
                help="Optional (e.g. DEL)"
                error={errors.patientResearchStudy}
                {...register("patientResearchStudy", {
                  // Text field takes priority if overwriting BEST4 study arm choice
                  onBlur: (e) => setField("patientResearchStudy", e.target.value),
                  onChange: (e) => setField("patientResearchStudy", e.target.value),
                })}
              />
            </div>
            <div className="column is-5 is-4-desktop is-3-widescreen">
              <FormInput
                id="patientResearchId"
                label="Research patient ID"
                disabled={formLocked}
                className="is-uppercase"
                help="Required for BEST4 cases"
                error={errors.patientResearchId}
                {...register("patientResearchId", {
                  setValueAs: (value: string) => value.toUpperCase(),
                })}
              />
            </div>
          </div>
        </Box>

        <Box title="Clinical information">
          <div className={`tabs ${formLocked ? "is-disabled" : ""}`}>
            <ul>
              {staticOptions.requestFormType.map((formType: RequestFormType) => (
                <li
                  key={formType}
                  className={formType === watchRequestFormType ? "is-active" : ""}
                >
                  <a
                    href=""
                    onClick={(e: MouseEvent) => handleClinicalTabClick(e, formType)}
                  >
                    {requestFormTypeLabels[formType]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="columns mb-1">
            {watchRequestFormType === RequestFormType.REFLUX && (
              <div className="column pb-0">
                <RadioGroup
                  id="hasRefluxSymptoms"
                  label="Does the patient currently have heartburn/reflux symptoms?"
                  disabled={formLocked}
                  options={staticOptions.yesNoUnanswered}
                  error={errors.hasRefluxSymptoms}
                  {...register("hasRefluxSymptoms")}
                />
                <RadioGroup
                  id="isTakingPPI"
                  label="Is the patient taking PPI?"
                  disabled={formLocked}
                  options={staticOptions.yesNoUnanswered}
                  error={errors.isTakingPPI}
                  {...register("isTakingPPI")}
                />
                <RadioGroup
                  id="hasEoEDiagnosis"
                  label="Is there an Eosinophilic oesophagitis (EoE) diagnosis?"
                  disabled={formLocked}
                  options={staticOptions.yesNoUnknownUnanswered}
                  error={errors.hasEoEDiagnosis}
                  {...register("hasEoEDiagnosis")}
                />
                <RadioGroup
                  id="smokingHistory"
                  label="Smoking history"
                  disabled={formLocked}
                  options={staticOptions.smokingHistory}
                  error={errors.smokingHistory}
                  {...register("smokingHistory")}
                />
              </div>
            )}
            {watchRequestFormType === RequestFormType.SURVEILLANCE && (
              <div className="column pb-0">
                <RadioGroup
                  id="hadDysplasia"
                  label="Has the patient ever had dysplasia?"
                  disabled={formLocked}
                  options={staticOptions.yesNoUnknownUnanswered}
                  error={errors.hadDysplasia}
                  {...register("hadDysplasia", {
                    // Reset follow-up question
                    onChange: () => setField("dysplasiaGrade", null),
                  })}
                />
                <RadioGroup
                  indented
                  id="dysplasiaGrade"
                  label="If Yes, what was the dysplasia grade?"
                  disabled={formLocked || watchHadDysplasia !== Answer.YES}
                  options={staticOptions.dysplasiaGrade}
                  error={errors.dysplasiaGrade}
                  {...register("dysplasiaGrade")}
                />
                <RadioGroup
                  id="hadEndoscopicTreatment"
                  label="Has the patient ever had endoscopic treatment?"
                  disabled={formLocked}
                  options={staticOptions.yesNoUnknownUnanswered}
                  error={errors.hadEndoscopicTreatment}
                  {...register("hadEndoscopicTreatment")}
                />
                <RadioGroup
                  id="isTakingPPI"
                  label="Is the patient taking PPI?"
                  disabled={formLocked}
                  options={staticOptions.yesNoUnanswered}
                  error={errors.isTakingPPI}
                  {...register("isTakingPPI")}
                />
                <RadioGroup
                  id="smokingHistory"
                  label="Smoking history"
                  disabled={formLocked}
                  options={staticOptions.smokingHistory}
                  error={errors.smokingHistory}
                  {...register("smokingHistory")}
                />
              </div>
            )}
            {watchRequestFormType === RequestFormType.SURVEILLANCE && (
              <div className={classNames("field column", { disabled: formLocked })}>
                <div className="columns is-mobile">
                  <div className="column is-half">
                    <FormDateInput
                      id="dateLastEndoscopy"
                      label="Last diagnostic endoscopy"
                      disabled={formLocked}
                      mode="month"
                      control={control}
                      help="Select January if only the year was provided"
                      error={errors.dateLastEndoscopy}
                    />
                  </div>
                </div>
                <h4 className="label has-text-weight-bold is-size-6 mb-1">
                  Prague classification
                </h4>
                <div className="columns is-mobile mb-0">
                  <div className="column is-half">
                    <FormInput
                      type="number"
                      step={PRAGUE_CLASSIFICATION_DIVISOR}
                      id="circumferentialLength"
                      label="C (cm)"
                      disabled={formLocked}
                      help="Leave empty if not provided"
                      error={errors.circumferentialLength}
                      {...register("circumferentialLength", {
                        valueAsNumber: true,
                      })}
                    />
                  </div>
                  <div className="column is-half">
                    <FormInput
                      type="number"
                      step={PRAGUE_CLASSIFICATION_DIVISOR}
                      id="maximalLength"
                      label="M (cm)"
                      disabled={formLocked}
                      help="Leave empty if not provided"
                      error={errors.maximalLength}
                      {...register("maximalLength", { valueAsNumber: true })}
                    />
                  </div>
                </div>
                {!!pragueClassificationWarning && (
                  <WarningMessage testId={TEST_ID_FORM_PRAGUE_CLASSIFICATION_WARNING}>
                    {pragueClassificationWarning}
                  </WarningMessage>
                )}
              </div>
            )}
          </div>
          <FormCheckbox
            addMargin
            id="hasAdditionalClinicalInformation"
            heading="Additional clinical details"
            label="More information on request form"
            disabled={formLocked}
            {...register("hasAdditionalClinicalInformation")}
          />
          <div className="columns">
            <div className="column is-half">
              <FormSelect
                noMargin
                clearable
                id="patientResearchStudy"
                label="BEST4 study arm (optional)"
                disabled={formLocked}
                control={control}
                options={Best4StudyArms.filter(
                  (s) => s.caseType === watchRequestFormType
                )}
                help="Select _repeat option if box was ticked"
              />
            </div>
          </div>
        </Box>

        <Box
          title="Procedure details"
          addon={
            !formLocked && (
              <button
                type="button"
                disabled={formLocked}
                className="button is-small is-link is-light ml-3"
                data-testid={TEST_ID_FORM_IDEAL_PROCEDURE_BUTTON}
                onClick={fillIdealProcedureAnswers}
              >
                <FontAwesomeIcon icon={faBolt} className="mr-2" />
                No issues
              </button>
            )
          }
        >
          <div className="columns">
            <div className="column is-half">
              <RadioGroup
                id="didPatientSwallow"
                label="Do you think the patient swallowed the capsule so it reached their stomach?"
                disabled={formLocked}
                options={staticOptions.yesNoUnanswered}
                error={errors.didPatientSwallow}
                {...register("didPatientSwallow")}
              />
              <RadioGroup
                id="didSpongeExpand"
                label="Did the sponge fully expand?"
                disabled={formLocked}
                options={staticOptions.yesNoUnanswered}
                error={errors.didSpongeExpand}
                {...register("didSpongeExpand")}
              />
              <RadioGroup
                id="wasTensionOnThread"
                label="Was there tension on the thread throughout withdrawal?"
                disabled={formLocked}
                options={staticOptions.yesNoUnanswered}
                error={errors.wasTensionOnThread}
                {...register("wasTensionOnThread")}
              />
              <RadioGroup
                id="isDebrisOnSponge"
                label="Is there debris on the sponge?"
                disabled={formLocked}
                options={staticOptions.yesNoUnanswered}
                error={errors.isDebrisOnSponge}
                {...register("isDebrisOnSponge")}
              />
              <RadioGroup
                id="isBloodOnSponge"
                label="Is there blood on the sponge?"
                disabled={formLocked}
                options={staticOptions.yesNoUnanswered}
                error={errors.isBloodOnSponge}
                {...register("isBloodOnSponge", {
                  // Reset follow-up question
                  onChange: () => setField("takesAnticoagulants", null),
                })}
              />
              <RadioGroup
                indented
                id="takesAnticoagulants"
                label="If Yes, does the patient take anticoagulants?"
                disabled={formLocked || watchIsBloodOnSponge !== Answer.YES}
                options={staticOptions.yesNoUnanswered}
                error={errors.takesAnticoagulants}
                {...register("takesAnticoagulants")}
              />
            </div>
            <div className="column is-half">
              <FormInput
                addMargin
                id="practitionerName"
                label="Practitioner name"
                disabled={formLocked}
                help="Healthcare professional who performed the procedure"
                error={errors.practitionerName}
                {...register("practitionerName")}
              />
              <FormInput
                addMargin
                id="practitionerLocation"
                label="Practitioner’s hospital/site/practice"
                disabled={formLocked}
                readOnly={watchUseClinicianForProcedureSite}
                error={errors.practitionerLocation}
                {...register("practitionerLocation")}
              />
              <div className="columns is-mobile">
                <div className="column is-half">
                  <FormDateInput
                    id="procedureDate"
                    label="Date of procedure"
                    disabled={formLocked}
                    control={control}
                    error={errors.procedureDate}
                  />
                  {!!procedureDateWarning && (
                    <WarningMessage testId={TEST_ID_FORM_PROCEDURE_DATE_WARNING}>
                      {procedureDateWarning}
                    </WarningMessage>
                  )}
                </div>
              </div>
              <RadioGroup
                id="numberOfFailedAttempts"
                label="Number of failed attempts"
                disabled={formLocked}
                options={staticOptions.numberOfFailedAttempts}
                error={errors.numberOfFailedAttempts}
                {...register("numberOfFailedAttempts")}
              />
            </div>
          </div>
        </Box>

        <Box title="Lab use only">
          <div className="columns">
            <div className="column is-4-desktop">
              {/* No need to ask for Barrett’s surveillance (TRF-2) cases */}
              {watchRequestFormType !== RequestFormType.SURVEILLANCE && (
                <RadioGroup
                  id="slides"
                  label="Slides"
                  disabled={formLocked}
                  options={staticOptions.slides}
                  error={errors.slides}
                  {...register("slides")}
                />
              )}
              <div className="mb-5">
                <FormDateInput
                  todayButton
                  id="dateReceived"
                  label="Receipt date"
                  disabled={formLocked}
                  control={control}
                  error={errors.dateReceived}
                />
                {!!dateReceivedWarning && (
                  <WarningMessage testId={TEST_ID_FORM_DATE_RECEIVED_WARNING}>
                    {dateReceivedWarning}
                  </WarningMessage>
                )}
              </div>
              <FormSelect
                id="pathologistId"
                label="Reporting pathologist"
                disabled={formLocked}
                control={control}
                options={pathologistOptions}
                error={errors.pathologistId}
              />
              <FormSelect
                id="consultant"
                label="LIMS consultant"
                disabled
                control={control}
                options={consultants}
                placeholder=""
                help="⚠ Controlled by reporting pathologist"
              />
            </div>
            <div className="column is-8-desktop">
              <FormTextArea
                id="macro"
                label="Macro"
                disabled={formLocked}
                error={errors.macro}
                {...register("macro")}
              />
              {!formLocked && (
                <div className="buttons are-small">
                  {MacroTextButtons.map(({ label, value, testId }) => (
                    <button
                      type="button"
                      key={testId}
                      className="button is-link is-light"
                      data-testid={testId}
                      onClick={() => setField("macro", value)}
                    >
                      <FontAwesomeIcon icon={faAdd} className="mr-2" />
                      {label}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Box>

        {/* Human-readable names for BEST4 */}
        <input type="hidden" id="caseOriginName" {...register("caseOriginName")} />
        <input type="hidden" id="consultantName" {...register("consultantName")} />

        {!formLocked && (
          <button
            type="submit"
            className="button is-primary is-large is-fullwidth"
            data-testid={TEST_ID_FORM_SAVE_CASE_BUTTON}
          >
            <FontAwesomeIcon icon={faUpRightFromSquare} className="mr-3" />
            {formMode === "create" ? "Submit case" : "Save changes"}
          </button>
        )}
      </form>
    );
  }
);

CaseForm.displayName = "CaseForm";

export default CaseForm;
