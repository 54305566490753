import classNames from "classnames";
import React from "react";
import { FieldError } from "react-hook-form";

interface RadioGroupProps {
  id: string;
  label: string;
  options: string[];
  indented?: boolean;
  disabled?: boolean;
  error?: FieldError;
}

const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>(
  ({ id, label, options, indented, disabled, error, ...props }, ref) => {
    return (
      <div
        role="radiogroup"
        aria-labelledby={`${id}Label`}
        className={classNames({
          "field mb-5": true,
          indented: indented,
          disabled: disabled,
        })}
      >
        <h4 id={`${id}Label`} className="label is-size-6 has-text-weight-bold">
          {label}
        </h4>
        <div className="control">
          {options.map((option) => {
            return (
              <label
                key={option}
                className={classNames({
                  radio: true,
                  "has-text-grey-light": disabled,
                })}
              >
                <input
                  type="radio"
                  value={option}
                  disabled={disabled}
                  ref={ref}
                  {...props}
                />
                {option}
              </label>
            );
          })}
        </div>
        {error && <p className="help has-text-danger">{error.message}</p>}
      </div>
    );
  }
);

RadioGroup.displayName = "RadioGroup";

export default RadioGroup;
