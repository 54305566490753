import React from "react";
import { useSelector } from "react-redux";

import { Environment, selectEnvironment } from "../../store/metadataSlice";

// No tag in local development or production
const tagColours: Record<Environment, string | null> = {
  "local-development": null,
  staging: "is-warning",
  "pre-production": "is-danger",
  production: null,
};

const EnvironmentTag = (): JSX.Element | null => {
  const environment = useSelector(selectEnvironment);
  const tagColour = tagColours[environment];

  if (!tagColour) {
    return null;
  }

  return <div className={`tag is-rounded is-uppercase ${tagColour}`}>{environment}</div>;
};

export default EnvironmentTag;
