import { Authenticator } from "@aws-amplify/ui-react";
import { faCheck, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import { I18n } from "aws-amplify/utils";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import DismissSnackbarButton from "./components/common/DismissSnackbarButton";
import CaseEdit from "./routes/CaseEdit";
import CaseList from "./routes/CaseList";
import NewCase from "./routes/NewCase";
import NotFound from "./routes/NotFound";
import Root from "./routes/Root";
import UserGroups from "./routes/UserGroups";
import UserList from "./routes/UserList";
import UserProfile from "./routes/UserProfile";
import { store } from "./store";

Sentry.init({
  dsn: "https://1bfd682bff93464e8167beff7c93b866@o429717.ingest.sentry.io/4505386753654784",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api.*pathkit\.cytedhealth\.com/],
  environment: import.meta.env.VITE_APP_ENVIRONMENT ?? "local-development",
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_APP_USER_POOL_APP_CLIENT_ID,
    },
  },
});

// Override Amplify Authenticator MFA user interface
I18n.setLanguage("en");
I18n.putVocabularies({
  en: { "Confirm TOTP Code": "Two-factor authentication" },
});
const formFields = {
  confirmSignIn: {
    confirmation_code: {
      type: "text", // Remove number arrow buttons
      label: "Authenticator app code",
      placeholder: "6-digit code",
      autoFocus: true,
    },
  },
};

const snackbarProps: SnackbarProviderProps = {
  maxSnack: 1,
  anchorOrigin: { vertical: "top", horizontal: "center" },
  iconVariant: {
    success: <FontAwesomeIcon className="mr-3" icon={faCheck} />,
    error: <FontAwesomeIcon className="mr-3" icon={faWarning} />,
  },
  action: (key) => <DismissSnackbarButton snackbarKey={key} />,
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <NewCase />,
      },
      {
        path: "cases",
        element: <CaseList />,
      },
      {
        path: "cases/:labNumber",
        element: <CaseEdit />,
      },
      {
        path: "users",
        element: <UserList />,
      },
      {
        path: "groups",
        element: <UserGroups />,
      },
      {
        path: "profile",
        element: <UserProfile />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Authenticator hideSignUp className="mt-6" formFields={formFields}>
      <SnackbarProvider {...snackbarProps}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </SnackbarProvider>
    </Authenticator>
  </React.StrictMode>
);
