import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface LoadingBackdropProps {
  open: boolean;
  error?: boolean;
  title?: string;
  subtitle?: string;
}

const LoadingBackdrop = ({
  open,
  error,
  title,
  subtitle,
}: LoadingBackdropProps): JSX.Element | null => {
  if (!open) return null;
  return (
    <div className="cy-loading-backdrop">
      <div className="cy-loading-backdrop__body">
        <div className={`cy-loading-backdrop__${error ? "error" : "loader"}`}>
          {error && <FontAwesomeIcon icon={faCircleXmark} size="3x" />}
        </div>
        <h1 className="title is-4 has-text-light">{title ?? "Loading"}</h1>
        <h2 className="subtitle has-text-light">{subtitle ?? "Just a moment..."}</h2>
      </div>
    </div>
  );
};

export default LoadingBackdrop;
