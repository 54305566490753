import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { MouseEvent, useState } from "react";

interface SearchPanelProps {
  title: string;
  expanded?: boolean;
  children: React.ReactNode;
}

const SearchPanel = ({
  title,
  expanded = false,
  children,
}: SearchPanelProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

  const toggleExpanded = (e: MouseEvent) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <fieldset className="cy-search-panel">
      <h4 className="title is-5 mb-0">
        <a
          href=""
          role="button"
          className="cy-search-panel__toggle"
          onClick={toggleExpanded}
        >
          <FontAwesomeIcon
            icon={faCaretRight}
            className={classNames({
              "cy-search-panel__toggle--icon": true,
              "fa-rotate-90": isExpanded,
            })}
          />
          {title}
        </a>
      </h4>
      <div
        className={classNames({
          "cy-search-panel__content": true,
          "is-hidden": !isExpanded,
        })}
      >
        {children}
      </div>
    </fieldset>
  );
};

export default SearchPanel;
