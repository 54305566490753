import { some } from "lodash";
import * as yup from "yup";

import { FormSelectOption } from "../components/forms/FormSelect";
import { FIELD_REQUIRED_ERROR } from "../helpers/strings";
import { patterns } from "../helpers/validation";

yup.setLocale({
  mixed: {
    required: FIELD_REQUIRED_ERROR,
  },
});

export enum RequestFormType {
  REFLUX = "TRF-1 (Reflux)",
  SURVEILLANCE = "TRF-2 (Barrett’s surveillance)",
}

export enum DeviceBrand {
  ENDOSIGN = "EndoSign",
  CYTOSPONGE = "Cytosponge",
}

export enum SlideOptions {
  HE_TFF3 = "H+E and TFF3",
  HE_TFF3_P53 = "H+E, TFF3 and p53",
}

export enum Answer {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
  YES = "Yes",
  NO = "No",
  UNKNOWN = "Don’t know",
  UNANSWERED = "Not provided",
  NEVER = "Never",
  FORMER = "Former",
  CURRENT = "Current",
  LOW = "Low",
  HIGH = "High",
  INDEFINITE = "Indefinite",
  NONE = "None",
  ONE = "1",
  TWO = "2",
}

export interface Best4StudyArm extends FormSelectOption {
  caseType: RequestFormType;
}

export const Best4StudyArms: Best4StudyArm[] = [
  {
    label: "B4_SCR_baseline",
    value: "B4_SCR_baseline",
    caseType: RequestFormType.REFLUX,
  },
  {
    label: "B4_SCR_baseline_repeat",
    value: "B4_SCR_baseline_repeat",
    caseType: RequestFormType.REFLUX,
  },
  {
    label: "B4_SUR_baseline",
    value: "B4_SUR_baseline",
    caseType: RequestFormType.SURVEILLANCE,
  },
  {
    label: "B4_SUR_baseline_repeat",
    value: "B4_SUR_baseline_repeat",
    caseType: RequestFormType.SURVEILLANCE,
  },
  {
    label: "B4_SUR_18month",
    value: "B4_SUR_18month",
    caseType: RequestFormType.SURVEILLANCE,
  },
  {
    label: "B4_SUR_18month_repeat",
    value: "B4_SUR_18month_repeat",
    caseType: RequestFormType.SURVEILLANCE,
  },
  {
    label: "B4_SUR_36month",
    value: "B4_SUR_36month",
    caseType: RequestFormType.SURVEILLANCE,
  },
  {
    label: "B4_SUR_36month_repeat",
    value: "B4_SUR_36month_repeat",
    caseType: RequestFormType.SURVEILLANCE,
  },
];

export const CaseSchemaOptions = {
  patientSex: [Answer.MALE, Answer.FEMALE, Answer.OTHER],
  requestFormType: Object.values(RequestFormType),
  yesNoUnknown: [Answer.YES, Answer.NO, Answer.UNKNOWN],
  yesNoUnanswered: [Answer.YES, Answer.NO, Answer.UNANSWERED],
  yesNoUnknownUnanswered: [Answer.YES, Answer.NO, Answer.UNKNOWN, Answer.UNANSWERED],
  smokingHistory: [Answer.NEVER, Answer.FORMER, Answer.CURRENT, Answer.UNANSWERED],
  dysplasiaGrade: [
    Answer.LOW,
    Answer.HIGH,
    Answer.INDEFINITE,
    Answer.UNKNOWN,
    Answer.UNANSWERED,
  ],
  numberOfFailedAttempts: [Answer.NONE, Answer.ONE, Answer.TWO],
  slides: Object.values(SlideOptions),
};

// SlideOptions displayed in UI, AllowedSlides expected by API
export type AllowedSlides = "TFF3" | "HAndE" | "P53";
export const SlidesMap: { [key in SlideOptions]: AllowedSlides[] } = {
  [SlideOptions.HE_TFF3]: ["HAndE", "TFF3"],
  [SlideOptions.HE_TFF3_P53]: ["HAndE", "TFF3", "P53"],
};

export const MacroTextButtons: { label: string; value: string; testId: string }[] = [
  {
    label: "No descriptive features",
    value: "No descriptive features noted in specimen during laboratory procedures.",
    testId: "MacroNormalButton",
  },
  {
    label: "Debris / food matter",
    value: "Debris/food matter noted in specimen during laboratory procedures.",
    testId: "MacroDebrisButton",
  },
];

export type UiCaseData = yup.InferType<typeof CaseSchema>;

// Some form values must be transformed prior to submission - mostly dates
// to strings - but the API also expects slides as an array of strings.
export type DbCaseData = Omit<
  UiCaseData,
  | "userGroupId"
  | "pathologistId"
  | "patientDateOfBirth"
  | "dateLastEndoscopy"
  | "procedureDate"
  | "dateReceived"
  | "slides"
> & {
  userGroupId: string | null; // Pre-1.44 cases may not have a userGroupId
  pathologistId: string | null; // Pre-1.55 cases may not have a pathologistId
  patientDateOfBirth: string;
  dateLastEndoscopy: string | null;
  procedureDate: string;
  dateReceived: string;
  slides: AllowedSlides[];
};

// About 1% of request forms specify measurements like 0.5, 1.5 or 2.5cm
export const PRAGUE_CLASSIFICATION_DIVISOR: number = 0.5;

const isSegmentLengthDivisible = (num?: number | null): boolean => {
  if (num === null || num === undefined) {
    // Empty values are allowed
    return true;
  } else {
    // Otherwise ensure that the value entered is divisible by 0.5
    return num % PRAGUE_CLASSIFICATION_DIVISOR === 0;
  }
};

export const inferDeviceBrandFromSpecimen = (
  specimenLabel?: string
): DeviceBrand | undefined => {
  const deviceBrand = Object.values(DeviceBrand).find((deviceBrand) =>
    specimenLabel?.toUpperCase().includes(deviceBrand.toUpperCase())
  );
  // deviceBrand should never be undefined because the filtered 'specimen' dropdown
  // list options in Dropdowns.kt should always correspond to a DeviceBrand. The
  // API will correctly reject submissions without a deviceBrand as this would make
  // it impossible to determine which specimen details report to save to the LIMS.
  return deviceBrand;
};

export const CaseSchema = yup
  .object({
    // Device details
    labNumber: yup
      .string()
      .required()
      .length(10, "Must be exactly 10 characters, e.g. 22CYT00302")
      .matches(patterns.labNumber, "Must be valid format, e.g. 22CYT00302"),
    recordNumber: yup
      .string()
      .required()
      .length(8, "Must be exactly 8 characters, e.g. 22P12345")
      .matches(patterns.recordNumber, "Must be valid format, e.g. 22P12345"),
    useRecordNumberForPatientName: yup.bool(),
    specimen: yup.string().required(),
    deviceId: yup.string().optional().nullable(), // May be null for pre-1.42 cases
    deviceBrand: yup.string().optional(),

    // Report recipient
    userGroupId: yup.string().required(),
    clinician: yup.string().required(),
    useClinicianForProcedureSite: yup.bool(),
    caseOrigin: yup.string().required(),

    // Patient details
    patientIdentifier: yup.string().trim().required(),
    patientIdentifierNotProvided: yup.bool(),
    patientIdentifierAlternative: yup
      .string()
      .nullable() // May be null for pre-1.37 cases
      .when("patientIdentifierNotProvided", {
        is: true,
        then: (schema) => schema.trim().required(),
        otherwise: (schema) => schema.trim().optional(),
      }),
    patientDateOfBirth: yup.date().typeError(FIELD_REQUIRED_ERROR).required(),
    patientDateOfBirthIsYearOnly: yup.bool(),
    patientSex: yup.string().required(),
    patientFirstName: yup.string().trim().required(),
    patientSurname: yup.string().trim().required(),
    patientInitials: yup.string().trim().required(),
    patientResearchStudy: yup.string().optional(),
    patientResearchId: yup
      .string()
      .trim()
      .when("patientResearchStudy", {
        // The BEST4 client requires a research patient ID; otherwise, this
        // field should be optional
        is: (value: string) => some(Best4StudyArms, ["value", value]),
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional(),
      }),
    // Clinical information
    requestFormType: yup.string().required(),
    hasRefluxSymptoms: yup
      .string()
      .nullable()
      .when("requestFormType", {
        is: RequestFormType.REFLUX,
        then: (schema) => schema.required(),
      }),
    hasEoEDiagnosis: yup
      .string()
      .nullable()
      .when("requestFormType", {
        is: RequestFormType.REFLUX,
        then: (schema) => schema.required(),
      }),
    dateLastEndoscopy: yup
      .date()
      .optional()
      .nullable()
      .transform((date) => date ?? null),
    circumferentialLength: yup
      .number()
      .nullable()
      .when("requestFormType", {
        is: RequestFormType.SURVEILLANCE,
        then: (schema) =>
          schema
            .min(0, "Must not be a negative number")
            .max(20, "Must not be greater than 20")
            .test(
              "is-segment-length-divisible",
              `Must be divisible by ${PRAGUE_CLASSIFICATION_DIVISOR}`,
              isSegmentLengthDivisible
            )
            // Send null when blank
            .transform((num) => (num === "" || isNaN(num) ? null : num)),
      }),
    maximalLength: yup
      .number()
      .nullable()
      .when("requestFormType", {
        is: RequestFormType.SURVEILLANCE,
        then: (schema) =>
          schema
            .min(0, "Must not be a negative number")
            .max(20, "Must not be greater than 20")
            .test(
              "is-segment-length-divisible",
              `Must be divisible by ${PRAGUE_CLASSIFICATION_DIVISOR}`,
              isSegmentLengthDivisible
            )
            // Send null when blank
            .transform((num) => (num === "" || isNaN(num) ? null : num)),
      }),
    hadDysplasia: yup
      .string()
      .nullable()
      .when("requestFormType", {
        is: RequestFormType.SURVEILLANCE,
        then: (schema) => schema.required(),
      }),
    dysplasiaGrade: yup
      .string()
      .nullable()
      .when("hadDysplasia", {
        is: Answer.YES,
        then: (schema) => schema.required(),
      }),
    hadEndoscopicTreatment: yup
      .string()
      .nullable()
      .when("requestFormType", {
        is: RequestFormType.SURVEILLANCE,
        then: (schema) => schema.required(),
      }),
    isTakingPPI: yup.string().required(),
    smokingHistory: yup.string().required(),
    hasAdditionalClinicalInformation: yup.bool(),

    // Procedure details
    practitionerName: yup.string().optional(),
    practitionerLocation: yup.string().optional(),
    numberOfFailedAttempts: yup.string().required(),
    procedureDate: yup.date().typeError(FIELD_REQUIRED_ERROR).required(),
    didPatientSwallow: yup.string().required(),
    didSpongeExpand: yup.string().required(),
    wasTensionOnThread: yup.string().required(),
    isDebrisOnSponge: yup.string().required(),
    isBloodOnSponge: yup.string().required(),
    takesAnticoagulants: yup
      .string()
      .nullable()
      .when("isBloodOnSponge", {
        is: Answer.YES,
        then: (schema) => schema.required(),
      }),

    // Lab use only
    dateReceived: yup.date().typeError(FIELD_REQUIRED_ERROR).required(),
    pathologistId: yup.string().required(),
    consultant: yup.string().required(),
    slides: yup
      .string()
      .nullable()
      .when("requestFormType", {
        is: RequestFormType.REFLUX,
        then: (schema) => schema.required(),
      }),
    macro: yup.string().trim().required(),

    // Hidden inputs with calculated values for BEST4
    caseOriginName: yup.string().required(),
    consultantName: yup.string().required(),

    // Not part of the form submission, but returned by case API
    limsCaseId: yup.string().optional(),
  })
  .required();
