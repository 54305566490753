import { IconDefinition, faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { MouseEvent } from "react";

import { CaseDialogType } from "../../routes/CaseEdit";
import { CaseInfo, CaseState } from "../../schemas/ApiSchema";

export const TEST_ID_CASE_STATE_BANNER = "CaseStateBanner";
export const TEST_ID_CASE_STATE_BANNER_ICON = "CaseStateBannerIcon";
export const TEST_ID_CASE_STATE_BANNER_TITLE = "CaseStateBannerTitle";
export const TEST_ID_CASE_STATE_BANNER_SUBTITLE = "CaseStateBannerSubtitle";
export const TEST_ID_CASE_STATE_BANNER_SAVE_CHANGES_BUTTON =
  "CaseStateBannerSaveChangesButton";
export const TEST_ID_CASE_STATE_BANNER_LOCK_UNLOCK_BUTTON =
  "CaseStateBannerLockUnlockButton";

interface CaseStateBannerProps {
  caseInfo: CaseInfo;
  isFormDirty: boolean;
  pathologistName?: string;
  submitCaseForm: () => void;
  openCaseDialog: (d: CaseDialogType, e?: MouseEvent) => void;
}

export const CaseStateLabels: Record<CaseState, string> = {
  [CaseState.DELEGATED_TO_LIMS]: "Locked",
  [CaseState.AWAITING_SLIDES]: "Booked in",
  [CaseState.READY_FOR_PATHOLOGIST]: "Waiting for pathologist",
  [CaseState.REPORT_SUBMITTED]: "Waiting for pathologist",
  [CaseState.LOCKED]: "Locked",
  [CaseState.WITH_THE_LAB]: "Waiting for lab",
};

const CaseStateBanner = ({
  caseInfo: {
    caseState,
    permissions: { canEditCase, operations },
  },
  isFormDirty,
  pathologistName,
  submitCaseForm,
  openCaseDialog,
}: CaseStateBannerProps): JSX.Element => {
  const getCaseStateReasonLabel = (): string | null => {
    switch (caseState) {
      case CaseState.DELEGATED_TO_LIMS:
        return "This case cannot be managed in LabKit";
      case CaseState.LOCKED:
        // We can't yet be certain why the case was most recently locked
        return null;
      case CaseState.READY_FOR_PATHOLOGIST:
      case CaseState.REPORT_SUBMITTED:
        return (
          CaseStateLabels[caseState] + (pathologistName ? ` (${pathologistName})` : "")
        );
      default:
        return CaseStateLabels[caseState];
    }
  };

  const bannerColor: string = canEditCase ? "is-link" : "is-warning";
  const bannerIcon: IconDefinition = canEditCase ? faUnlock : faLock;
  const bannerTitle: string = canEditCase ? "Open" : "Locked";
  const bannerSubtitle: string | null = getCaseStateReasonLabel();
  const showSaveChangesButton: boolean = canEditCase && isFormDirty;

  return (
    <div
      className={`message is-light ${bannerColor}`}
      data-testid={TEST_ID_CASE_STATE_BANNER}
    >
      <div className="message-body">
        <div className="columns is-mobile is-vcentered">
          <div className="column is-narrow">
            <FontAwesomeIcon
              icon={bannerIcon}
              size="xl"
              data-testid={TEST_ID_CASE_STATE_BANNER_ICON}
            />
          </div>
          <div className="column">
            <p
              className="has-text-weight-bold"
              data-testid={TEST_ID_CASE_STATE_BANNER_TITLE}
            >
              {bannerTitle}
            </p>
            {!!bannerSubtitle && (
              <p data-testid={TEST_ID_CASE_STATE_BANNER_SUBTITLE}>{bannerSubtitle}</p>
            )}
          </div>
          <div className="column is-narrow">
            <div className="buttons">
              {showSaveChangesButton && (
                <button
                  type="button"
                  className={classNames("button", bannerColor)}
                  onClick={submitCaseForm}
                  data-testid={TEST_ID_CASE_STATE_BANNER_SAVE_CHANGES_BUTTON}
                >
                  Save changes
                </button>
              )}
              {!isEmpty(operations) && (
                <button
                  type="button"
                  className={classNames("button", bannerColor, {
                    // Becomes secondary action when there are unsaved changes
                    "is-outlined": showSaveChangesButton,
                  })}
                  onClick={(e) => openCaseDialog(CaseDialogType.STATE, e)}
                  data-testid={TEST_ID_CASE_STATE_BANNER_LOCK_UNLOCK_BUTTON}
                >
                  {operations[0].operation} case
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStateBanner;
