import { isBefore, subMonths } from "date-fns";

export const patterns = {
  recordNumber: /^\d{2}P\d{5}$/i,
  labNumber: /^\d{2}CYT\d{5}$/i,
};

export const isDateMoreThanOneMonthAgo = (date: Date) => {
  const oneMonthAgo = subMonths(new Date(), 1);
  return isBefore(date, oneMonthAgo);
};
