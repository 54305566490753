import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from ".";
import { FormSelectOption } from "../components/forms/FormSelect";
import { CHECK_LIMS } from "../helpers/strings";
import {
  Pathologist,
  isValidPathologist,
  mapPathologistsToListOptions,
} from "../schemas/PathologistSchema";
import { UserGroup, mapUserGroupsToListOptions } from "../schemas/UserGroupSchema";
import { UserStatus } from "../schemas/UserSchema";
import { CustomError, dataService } from "../services/data.service";

export type Environment =
  | "local-development"
  | "staging"
  | "pre-production"
  | "production";

export interface BootData {
  limsOptions: LimsOptions;
  userGroups: UserGroup[];
  pathologists: Pathologist[];
}

export interface LimsOptions {
  caseOrigins: FormSelectOption[];
  clinicians: FormSelectOption[];
  consultants: FormSelectOption[];
  specimens: FormSelectOption[];
}

export interface MetadataState {
  status: "booting" | "pending" | "succeeded" | "failed";
  error?: CustomError;
  environment: Environment;
  bootData: BootData;
}

export const fetchBootData = createAsyncThunk<
  BootData,
  void,
  { rejectValue: CustomError }
>("metadata/fetchBootData", async (_, { rejectWithValue }) => {
  const response = await dataService.getBootData();
  return response.data ?? rejectWithValue(response.error);
});

const initialState: MetadataState = {
  status: "booting",
  error: undefined,
  environment: import.meta.env.VITE_APP_ENVIRONMENT ?? "local-development",
  bootData: {
    limsOptions: {
      caseOrigins: [],
      clinicians: [],
      consultants: [],
      specimens: [],
    },
    userGroups: [],
    pathologists: [],
  },
};

export const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBootData.pending, (state) => {
        if (state.status !== "booting") state.status = "pending";
      })
      .addCase(fetchBootData.fulfilled, (state, action: PayloadAction<BootData>) => {
        state.status = "succeeded";
        state.bootData = action.payload;
      })
      .addCase(fetchBootData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Selectors
export const selectEnvironment = (state: RootState) => state.metadata.environment;
export const selectLimsOptions = (state: RootState) =>
  state.metadata.bootData.limsOptions;
export const selectUserGroups = (state: RootState) => state.metadata.bootData.userGroups;
export const selectPathologists = (state: RootState) =>
  state.metadata.bootData.pathologists;

// Pass a category and ID (e.g. "consultants" and "96a5913d-513d-493c-b2dd-1cbb5f7868ca")
// to get the display name like "Dr Wilson" from the limsOptions stored in global state.
// If the value can't be found, instead return "[Check LIMS]" rather than nothing.
export const selectLimsOptionLabel =
  (category: keyof LimsOptions, value?: string | null) =>
  (state: RootState): string => {
    const options = selectLimsOptions(state);
    return (
      options[category].find((option) => option.value === value)?.label ?? CHECK_LIMS
    );
  };

export const selectUserGroupLabel =
  (id?: string | null) =>
  (state: RootState): string => {
    const groups: UserGroup[] = selectUserGroups(state);
    return groups.find(({ userGroupId }) => userGroupId === id)?.userGroupName ?? "-";
  };

export const selectAllUserGroupOptions = createSelector(
  selectUserGroups,
  (userGroups): FormSelectOption[] => mapUserGroupsToListOptions(userGroups)
);

export const selectValidUserGroupOptions = createSelector(
  [selectUserGroups, selectLimsOptions],
  (userGroups, { clinicians, caseOrigins }): FormSelectOption[] => {
    // Omit user groups lacking known LIMS clinician and/or LIMS case origin mappings
    const validUserGroups: UserGroup[] = userGroups.filter(({ procurementRoutes }) => {
      const { limsClinicianId, limsCaseOriginId } = procurementRoutes[0] || {};
      return (
        clinicians.some(({ value }) => limsClinicianId === value) &&
        caseOrigins.some(({ value }) => limsCaseOriginId === value)
      );
    });
    return mapUserGroupsToListOptions(validUserGroups);
  }
);

export const selectPathologistLabel =
  (id?: string | null) =>
  (state: RootState): string => {
    const pathologists: Pathologist[] = selectPathologists(state);
    return (
      pathologists.find(({ pathkitUserId }) => pathkitUserId === id)?.fullName ?? "-"
    );
  };

export const selectAllPathologistOptions = createSelector(
  selectPathologists,
  (pathologists): FormSelectOption[] => {
    const validPathologists: Pathologist[] = pathologists.filter(isValidPathologist);
    return mapPathologistsToListOptions(validPathologists);
  }
);

export const selectActivePathologistOptions = createSelector(
  selectPathologists,
  (pathologists): FormSelectOption[] => {
    const activePathologists: Pathologist[] = pathologists
      .filter(isValidPathologist)
      .filter(({ status }) => status === UserStatus.ENABLED);
    return mapPathologistsToListOptions(activePathologists);
  }
);

export default metadataSlice.reducer;
